<template>
  <div class="form-item-input">
    <label v-if="showLabel" :class="{ 'error-label': !validation.valid }">
      {{ label }}
    </label>

    <div
      class="form-item-input-wrapper"
      :class="{ 'error-input': !validation.valid }"
    >
      <input
        :id="id"
        :type="type"
        :name="name"
        :disabled="isDisabled"
        @keypress="getInputValue()"
        @keyup.enter="$emit('enterPress')"
        @input="
          $emit('input', $event.target.value),
            checkField($event.target.value),
            $emit('emailCheck', $event)
        "
      />

      <slot v-if="controller" name="controller"></slot>
    </div>

    <template v-if="validation">
      <div v-if="!validation.valid" class="error-wrapper">
        <span class="error-message">{{ validation.error }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { validations } from '../../../types/validations';
import { errors } from '../../../types/errors';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    type: {
      type: String
    },
    step: {
      type: String
    },
    name: {
      type: String
    },
    controller: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return {
          valid: true,
          error: '',
          value: ''
        };
      }
    }
  },

  data() {
    return {
      valid: this.validation,
      validation_fields: validations,
      field_validation: null,
      availableError: null
    };
  },

  methods: {
    getInputValue() {
      this.$emit('press');
    },
    checkField(value) {
      const NO_VALIDS_AVAILABLE = 'NO_VALIDS_AVAILABLE';
      let check =
        this.field_validation !== null ? this.field_validation.regexp : false;

      let checkVal = check ? check.test(value) : NO_VALIDS_AVAILABLE;

      if (!checkVal) {
        this.valid = {
          error: this.availableError,
          valid: false,
          visible: true,
          value: value
        };

        this.$emit('errorExist', {
          error: this.availableError,
          valid: false,
          visible: true,
          value: value
        });
      } else if (checkVal == NO_VALIDS_AVAILABLE) {
        this.valid = {
          error: '',
          valid: true,
          visible: true,
          value: value
        };

        this.$emit('errorExist', this.valid);
      } else {
        this.valid = {
          error: '',
          valid: true,
          visible: true,
          value: value
        };
        this.$emit('errorExist', {
          error: '',
          valid: true,
          visible: true,
          value: value
        });
      }
    },

    getFieldError() {
      /*
        Get the field error by field name (props: name)
      */
      errors.forEach(error => {
        if (error.field.name == this.name) {
          this.availableError = error.error_type;
        }
      });
    },
    getFieldValidation() {
      /*
        Get the field validation by field name (props: name)
      */
      validations.forEach(field => {
        if (field.field_name === this.name) {
          this.field_validation = field;
        }
      });
    }
  },

  mounted() {
    this.getFieldError();
    this.getFieldValidation();
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
