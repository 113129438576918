export function checkError(error) {
	let msg;
	let fieldName = error[0]

	console.log('INSIDE_ERR:', fieldName)

	errors.forEach(err => {
		if (err.field.name === fieldName) {
			msg = err
		}
	})

	return msg
}

export const errors = [
	{
		error_type: 'Der angegebene Wert ist ungültig',
		field: {
			name: 'email',
			email: ['Der angegebene Wert ist ungültig']
		}
	},
	{
		error_type: 'Die E-Mail-Adressen stimmen nicht überein.',
		field: {
			name: 'email_confirm',
			email_confirm: ['Die E-Mail-Adressen stimmen nicht überein.']
		}
	},
	{
		error_type: 'Bitte geben Sie eine Telefonnummer in folgendem Format an: 069-12345678',
		field: {
			name: 'telefonnummer',
			telefonnummer: ['Bitte geben Sie eine Telefonnummer in folgendem Format an: 069-12345678']
		}
	},
	{
		error_type: 'Die eingegebene IBAN ist nicht korrekt',
		field: {
			name: 'iban',
			iban: ['Die eingegebene IBAN ist nicht korrekt']
		}
	},
	{
		error_type:'Request processing error',
		field: false
	}
]